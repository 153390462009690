
.button--pen{
    --main_color : #4d4d4d ;
    --sub_color1 : #FFF ;
    --sub_color2 : #e9b800 ;
    --base_color : #e9b800 ;
    --border_radius1 : 60px 60px 40px 40px / 48px 48px 30px 30px ;
    --border_radius2 : 70px 70px 40px 40px / 48px 48px 30px 30px ;
    --border_radius3 : 40px 40px 40px 40px / 48px 48px 30px 30px ;
}
.button{
    position : relative ;
    width : 200px ;
    height : 60px ;
    text-decoration : none ;
    border : solid 3px #000 ;
    border-radius : 40px ;
    background : var(--main_color) ;
    font-family: 'Roboto Mono', monospace;
    
    @media (max-width: 1180px) {
        width : 180px ;
    height : 60px ;
       }
}

.button__wrapper{
    display : flex ;
    justify-content : center ;
    align-items : center ;
    position : relative ;
    z-index : 1 ; 
    width : 100% ;
    height : 100% ;
    border-radius : 40px ;
    overflow : hidden ;
}
.button__wrapper::before,
.button__wrapper::after{
    transition : all .5s ease ;
}
.characterBox{
    position : absolute ;
    top : -54px ;
    left : 0 ;
    right : 0 ;
    margin : 0 auto ;
    display : flex ;
    justify-content : space-between ;
    align-items : flex-end ;
    width : 180px ;
    height : 56px ;
    @media (max-width: 1180px) {
        width : 160px ;
  
       }
    
}
.button__text{
    position : relative ;
    z-index : 3 ;
    font-size : 25px ;
    letter-spacing : 4px ;
    color : var(--base_color) ;
    transition : all .3s ease ;
}
.character{
    position : relative ;
    width : 45px ;
    height : 36px ;
    box-sizing : border-box ;
    border : solid 3px #000 ;
    background : var(--main_color) ;
    border-radius : var(--border_radius1) ;
    animation : sleep 1s ease infinite alternate ;
}
.character::before{
    content : '' ;
    position : absolute ;
    top : -12px ;
    left : 22px ;
    width : 12px ;
    height : 12px ;
    background : #000 ;
    clip-path : path('M10.23,3.32c-3.54,.63-5.72,2.51-7.02,4.23-.33-1.58-.34-3.54,.93-5.12,.52-.65,.41-1.59-.24-2.11C3.24-.19,2.29-.08,1.77,.57c-3.82,4.77-.31,11.11-.13,11.42,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0-.01-.02,2.49,.04,2.52,0,.1-.14,1.54-4.82,6.59-5.71,.82-.14,1.37-.92,1.22-1.74s-.94-1.36-1.75-1.21Z') ;
}
.character__face{
    position : absolute ;
    z-index : 2 ;
    top : 15px ;
    left : 0 ;
    right : 0 ;
    margin : 0 auto ;
    width : 12px ;
    height : 6px ;
    background : var(--sub_color2) ;
    border-radius : 50% 50% 50% 50% / 78% 78% 22% 22% ;
    transition : .2s ;
}
.character__face::before,
.character__face::after{
    content : '' ;
    position : absolute ;
    top : -4px ;
    width : 8px ;
    height : 2px ;
    border-radius : 4px ;
    background : #000 ;
}
.character__face::before{
    left : -5px ;
}
.character__face::after{
    right : -5px ;
}



.button--pen .charactor__face2{
    position : absolute ;
    z-index : 1 ;
    bottom : 0 ;
    left : 0 ;
    right : 0 ;
    margin : auto ;
    width : 30px ;
    height : 10px ;
    border-radius : 50% ;
    background : #fff ;
    animation : face_pen 1s ease infinite alternate ;
}
.button--pen .charactor__face2::before,
.button--pen .charactor__face2::after{
    content : '' ;
    position : absolute ;
    top : -8px ;
    width : 17px ;
    height : 10px ;
    border-radius : 50% ;
    background : #fff ;
}
.button--pen .charactor__face2::before{
    left : -4px ;
}
.button--pen .charactor__face2::after{
    right : -4px ;
}


.button--pen::before{
    clip-path : path('M23.36,10.63L6.18,.13c-.93-.57-1.85,.81-1.39,2.06l1.44,3.91c.3,.81,.01,1.81-.6,2.1L.72,10.58c-.96,.47-.96,2.37,0,2.83l4.91,2.38c.62,.3,.9,1.29,.6,2.1l-1.44,3.91c-.46,1.26,.46,2.63,1.39,2.06L23.36,13.37c.86-.53,.86-2.22,0-2.75Z') ;
}

.button--pen .button__wrapper::before,
.button--pen .button__wrapper::after{
    content : '' ;
    position : absolute ;
    width : 110px ;
    height : 60px ;
    background : var(--sub_color1) ;
    clip-path : path('M9.12,14.14L43.15,.5c1.61-.86,3.57-.59,4.9,.68l19.55,14.31c.42,.4,.76,.89,.99,1.42l3.45,13.09c.89,2.06,3.18,3.09,5.28,2.37l11.76-4.86c1.56-.54,3.29-.11,4.43,1.1l11.93,12.39c.48,.51,.84,1.12,1.03,1.8l3.35,11.62c.8,2.79-1.25,5.58-4.1,5.58H4.27c-2.71,0-4.73-2.54-4.18-5.24L6.92,17.11c.26-1.28,1.07-2.37,2.2-2.97Z') ;
}
.button--pen .button__wrapper::before{
    left : -16px ;
    bottom : -10px ;
}
.button--pen .button__wrapper::after{
    right : -8px ;
    bottom : -20px ;
    transform : rotateY(180deg) ;
}
.button:hover .button__wrapper::before{
    transform : translateX(-12px) ;
}
.button:hover .button__wrapper::after{
    transform : rotateY(180deg) translateX(-12px) ;
}
.button:hover .button__text{
    letter-spacing : 6px ;
}
.button:hover::before{
    right : 14px ;
}
.button:hover .wakeup{
    animation : wakeup .2s ease ;
    animation-fill-mode : forwards ;
}
.button:hover .wakeup .character__face{
    top : 20px ;
}
.button:hover .wakeup .character__face::before,
.button:hover .wakeup .character__face::after{
    animation : eye 5s linear infinite ;
}
.button:hover .wakeup:nth-child(2) .character__face::before,
.button:hover .wakeup:nth-child(2) .character__face::after{
    animation : eye_2 5s linear infinite ;
}

.button--pen:hover .wakeup .charactor__face2{
    animation : face_pen_wakeup .2s ease ;
    animation-fill-mode : forwards ;
}
.button--pen:hover .wakeup .charactor__face2::before,
.button--pen:hover .wakeup .charactor__face2::after{
    top : -12px ;
    height : 18px ;
}


/* 本体と関係ないスタイル */
.container{
    display : flex ;
    flex-direction : column ;
    margin-top: 4rem;
    margin-left: 14rem;

    @media (max-width: 1180px) {
        margin-left: 1.5rem;
        margin-top: 3.5rem;
       }
}

@keyframes sleep{
    0%  {
        height : 36px ;
        border-radius : var(--border_radius1) ;
    }
    100%{
        height : 32px ;
        border-radius : var(--border_radius2) ;
    }
}
@keyframes wakeup{
    0%  {
        height : 32px ;
        border-radius : var(--border_radius2) ;
    }
    100%{
        height : 56px ;
        border-radius : var(--border_radius3) ;
    }
}
@keyframes eye {
    0%{
        top : -6px ;
        width : 6px ;
        height : 6px ;
    }
    30%{
        top : -6px ;
        width : 6px ;
        height : 6px ;
    }
    32%{
        top : -4px ;
        width : 8px ;
        height : 2px ;
    }
    34%{
        top : -6px ;
        width : 6px ;
        height : 6px ;
    }
    70%{
        top : -6px ;
        width : 6px ;
        height : 6px ;
    }
    72%{
        top : -4px ;
        width : 8px ;
        height : 2px ;
    }
    74%{
        top : -6px ;
        width : 6px ;
        height : 6px ;
    }
    76%{
        top : -4px ;
        width : 8px ;
        height : 2px ;
    }
    78%{
        top : -6px ;
        width : 6px ;
        height : 6px ;
    }
    100%{
        top : -6px ;
        width : 6px ;
        height : 6px ;
    }
}
@keyframes eye_2 {
    0%{
        top : -6px ;
        width : 6px ;
        height : 6px ;
    }
    10%{
        transform : translateX(0);
    }
    12%{
        transform : translateX(3px);
    }
    20%{
        top : -6px ;
        width : 6px ;
        height : 6px ;
    }
    22%{
        top : -4px ;
        width : 8px ;
        height : 2px ;
    }
    24%{
        top : -6px ;
        width : 6px ;
        height : 6px ;
    }
    25%{
        transform : translateX(3px);
    }
    27%{
        transform : translateX(0);
    }
    74%{
        top : -6px ;
        width : 6px ;
        height : 6px ;
        transform : translateX(0);
    }
    76%{
        top : -4px ;
        width : 8px ;
        height : 2px ;
        transform : translateX(3px);
    }
    78%{
        top : -6px ;
        width : 6px ;
        height : 6px ;
    }
    80%{
        top : -4px ;
        width : 8px ;
        height : 2px ;
    }
    82%{
        top : -6px ;
        width : 6px ;
        height : 6px ;
    }
    85%{
        transform : translateX(3px);
    }
    87%{
        transform : translateX(0);
    }
    100%{
        top : -6px ;
        width : 6px ;
        height : 6px ;
        transform : translateX(0);
    }
}
@keyframes body_hoo{
    0%  {
        bottom : 2px ;
    }
    100%{
        bottom : 0 ;
    }
}
@keyframes body_hoo_wakeup{
    0%  {
        bottom : 2px ;
    }
    100%{
        bottom : 6px ;
    }
}
@keyframes face_pen{
    0%  {
        height : 14px ;
    }
    100%{
        height : 10px ;
    }
}
@keyframes face_pen_wakeup{
    0%  {
        height : 14px ;
    }
    100%{
        height : 28px ;
    }
}







